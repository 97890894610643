import styled from 'styled-components'
import Box from './Box'
import Image from './Image'
import Text from './Text'
import Title from './Title'

const IndexBlockA = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: end;
  height: 100%;
  justify-content: space-between;

  @media (max-width: 992px) {
    padding: 0 0 100px 0;
  }
  @media screen and (max-width: 576px) {
    padding: 0 0 20px 0;
  }
`

IndexBlockA.Box = Box
IndexBlockA.Image = Image
IndexBlockA.Text = Text
IndexBlockA.Title = Title

export default IndexBlockA
