import styled from 'styled-components'

const Text = styled.p`
  color: #212121;
  font-size: 12px;
  letter-spacing: 3.6px;
  line-height: 1.5;
  background-color: #f2f1f178;
  font-weight: 400;
  display: inline-grid;
  max-width: 90%;

  @media (max-width: 767px) {
    visibility: hidden;
  }
`

export default Text
