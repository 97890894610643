import GatsbyImage from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const Img = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;

  @media (max-width: 992px) {
    padding: 0 20px 90px 20px;
  }

  @media screen and (max-width: 576px) {
    position: relative;
    padding-bottom: 0;
  }
`

const Image = props => <Img {...props} className='lazyload' />

export default Image
