import styled from 'styled-components'

const Tag = styled.p`
  font-size: 12px;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: 3.6px;
  font-weight: 400;
  font-family: 'Merriweather', serif;
  padding-right: 12px;
  color: #6f6f6f;
`

export default Tag
