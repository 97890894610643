import styled from 'styled-components'

const SubTitle = styled.p`
  color: #6b6b6b;
  font-family: 'Merriweather', serif;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  margin-bottom: 25px;
  font-weight: 400;
`

export default SubTitle
