import styled from 'styled-components'

const Title = styled.h5`
  font-size: 30px;
  letter-spacing: 10.8px;
  line-height: 0.9;
  padding-bottom: 14px;
  font-weight: 700;
  color: #000000;

  @media (max-width: 992px) {
    font-size: 28px;
  }

  @media (max-width: 481px) {
    font-size: 20px;
  }
`

export default Title
