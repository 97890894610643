import GatsbyImage from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const Img = styled(GatsbyImage)`
  width: 100%;
  max-width: 800px;
  height: auto;

  @media screen and (max-width: 1399px) {
    max-width: 650px;
  }
`

const Image = props => <Img {...props} className='lazyload' />

export default Image
