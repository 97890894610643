import styled from 'styled-components'

const Box = styled.div`
  padding: 70px 20px 58px 35px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`

export default Box
