import styled from 'styled-components'

const Title = styled.h5`
  color: #000000;
  font-size: 30px;
  letter-spacing: 7px;
  padding-bottom: 22px;
  line-height: 1.2;
  overflow-wrap: break-word;
  margin-bottom: 150px;
  font-weight: 700;

  @media screen and (max-width: 1399px) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1199px) {
    margin-bottom: 20px;
    letter-spacing: 4px;
    font-size: 28px;
  }
  @media (max-width: 481px) {
    font-size: 20px;
  }
`

export default Title
