import React from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import Grid, { grid } from 'styled-components-grid'
import GridUnit from 'styled-components-grid/dist/cjs/components/GridUnit'
import ClientArea from '../../components/blocks/ClientArea'
import GalleryItem from '../../components/blocks/GalleryItem'
import IndexBlock3 from '../../components/blocks/IndexBlock3'
import IndexBlockA from '../../components/blocks/IndexBlockA'
import IndexBlockB from '../../components/blocks/IndexBlockB'
import IndexBlockC from '../../components/blocks/IndexBlockC'
import IndexBlockD from '../../components/blocks/IndexBlockD'
import IndexHeader from '../../components/blocks/IndexHeader'
import IndexTeam2 from '../../components/blocks/IndexTeam2'
import Container from '../../components/elements/Container'
import UniversalButton from '../../components/elements/UniversalButton/UniversalButton'
import Layout from '../../components/layouts/layout'

const BlocksContainer = styled.div`
  padding: 0 45px 90px 45px;

  @media screen and (max-width: 576px) {
    padding: 0 15px 20px 15px;
  }
`

const TextContainer = styled.div`
  ${props => grid.unit({ size: props.size || { sm: 1, md: 5 / 12, xl: 3 / 12 } })};
  position: relative;
  padding: 15px;

  ${UniversalButton} {
    position: relative;
    bottom: 0;
    right: 0;
    margin: 15px 0 20px auto;
  }
`

const Gallery = styled.div`
  ${grid({})};
  display: flex;
  flex-wrap: wrap;
  margin: 0 15px;
`

const ImageWrapper = styled(GridUnit)`
  display: block;
`

const ParagraphRenderer = props => <>{props.children}</>

const IndexHeaderButton = props => (
  <IndexHeader.Button href={props.href}>
    <IndexHeader.ButtonText>
      {props.children[0].props.value}
    </IndexHeader.ButtonText>
  </IndexHeader.Button>
)

const UniversalButtonRenderer = props => (
  <UniversalButton href={props.href}>
    <UniversalButton.Text>{props.children[0].props.value}</UniversalButton.Text>
  </UniversalButton>
)

const IndexBlockDRenderer = props => (
  <IndexBlockD.Button href={props.href}>
    <IndexBlockD.ButtonText>
      {props.children[0].props.value}
    </IndexBlockD.ButtonText>
  </IndexBlockD.Button>
)

const IndexPage = props => (
  <>
    <Helmet
      title={props.pageContext.data?.pageTitle}
      meta={[
        {
          name: 'description',
          content: props.pageContext.data?.seoDescription.seoDescription
        },
        {
          name: 'keywords',
          content: props.pageContext.data?.keywords.keywords
        },
        {
          name: 'robots',
          content: props.pageContext.data?.index
            ? props.pageContext.data?.index
            : 'index, follow'
        }
      ]}
      link={[
        {
          rel: 'canonical',
          href: props.pageContext.data?.canonical
            ? props.pageContext.data?.canonical
            : 'https://www.amber-living.de'
        }
      ]}
    />
    <Layout {...props} navigation={props.pageContext.navigation}>
      <IndexHeader>
        <IndexHeader.FakeDiv />
        <IndexHeader.Header>
          {props.pageContext.data?.block1Headline}
        </IndexHeader.Header>
        <IndexHeader.Image
          fluid={{ ...props.pageContext.data?.mediaImage1.fluid }}
        />
        <IndexHeader.BottomWrapper>
          <IndexHeader.Text>
            {props.pageContext.data?.block1Sub}
          </IndexHeader.Text>

          <ReactMarkdown
            source={props.pageContext.data?.block1Cta.block1Cta}
            renderers={{
              paragraph: ParagraphRenderer,
              link: IndexHeaderButton
            }}
          />
        </IndexHeader.BottomWrapper>
      </IndexHeader>
      <BlocksContainer>
        <Grid>
          <GridUnit size={{ sm: 1, md: 1, lg: 5 / 12 }}>
            <IndexBlockA>
              <IndexBlockA.Image
                fluid={{ ...props.pageContext.data?.mediaImage2[0].fluid }}
              />
              <IndexBlockA.Box>
                <IndexBlockA.Title>
                  {props.pageContext.data?.block2aHeadline}
                </IndexBlockA.Title>
                <IndexBlockA.Text>
                  {props.pageContext.data?.block2aSub}
                </IndexBlockA.Text>
                <ReactMarkdown
                  source={props.pageContext.data?.block2aCta.block2aCta}
                  renderers={{
                    paragraph: ParagraphRenderer,
                    link: UniversalButtonRenderer
                  }}
                />
              </IndexBlockA.Box>
            </IndexBlockA>
          </GridUnit>
          <GridUnit size={{ sm: 1, md: 1 / 2, lg: 4 / 12 }}>
            <IndexBlockB>
              <IndexBlockB.Box>
                <IndexBlockB.Title>
                  {props.pageContext.data?.block2bHeadline}
                </IndexBlockB.Title>
                <IndexBlockB.Text>
                  {props.pageContext.data?.block2bSub}
                </IndexBlockB.Text>
                <ReactMarkdown
                  source={props.pageContext.data?.block2bCta.block2bCta}
                  renderers={{
                    paragraph: ParagraphRenderer,
                    link: UniversalButtonRenderer
                  }}
                />
              </IndexBlockB.Box>
              <IndexBlockB.Image
                fluid={{ ...props.pageContext.data?.mediaImage2[1].fluid }}
              />
            </IndexBlockB>
          </GridUnit>
          <GridUnit size={{ sm: 1, md: 1 / 2, lg: 3 / 12 }}>
            <IndexBlockC>
              <IndexBlockC.Image
                fluid={{ ...props.pageContext.data?.mediaImage2[2].fluid }}
              />
              <IndexBlockC.Box>
                <IndexBlockC.Title>
                  {props.pageContext.data?.block2cHeadline}
                </IndexBlockC.Title>
                <IndexBlockC.Text>
                  {props.pageContext.data?.block2cSub}
                </IndexBlockC.Text>
                <ReactMarkdown
                  source={props.pageContext.data?.block2cCta.block2cCta}
                  renderers={{
                    paragraph: ParagraphRenderer,
                    link: UniversalButtonRenderer
                  }}
                />
              </IndexBlockC.Box>
            </IndexBlockC>
          </GridUnit>
        </Grid>
        <Grid valign='center' halign='center'>
          <GridUnit size={{ sm: 1, md: 1, lg: 1, xl: 6 / 12 }}>
            <IndexBlockD>
              <IndexBlockD.Box>
                <IndexBlockD.Title>
                  {props.pageContext.data?.block2dHeadline}
                </IndexBlockD.Title>
                <IndexBlockD.Text>
                  {props.pageContext.data?.block2dSub}
                </IndexBlockD.Text>
                <ReactMarkdown
                  source={props.pageContext.data?.block2dCta.block2dCta}
                  renderers={{
                    paragraph: ParagraphRenderer,
                    link: IndexBlockDRenderer
                  }}
                />
              </IndexBlockD.Box>
              <IndexBlockD.Image
                fluid={{ ...props.pageContext.data?.mediaImage2[3].fluid }}
              />
            </IndexBlockD>
          </GridUnit>
        </Grid>
      </BlocksContainer>
      <BlocksContainer>
        <IndexBlock3>
          <TextContainer>
            <IndexBlock3.Tag>
              {props.pageContext.data?.block3aTitleSmall}
            </IndexBlock3.Tag>
            <IndexBlock3.Title>
              {props.pageContext.data?.block3aHeadlineBig}
            </IndexBlock3.Title>
            <IndexBlock3.SubTitle>
              {props.pageContext.data?.block3aHeadlineSmall}
            </IndexBlock3.SubTitle>
            <IndexBlock3.Text>
              {props.pageContext.data?.block3aSub}
            </IndexBlock3.Text>
            <ReactMarkdown
              source={props.pageContext.data?.block3aCta.block3aCta}
              renderers={{
                paragraph: ParagraphRenderer,
                link: UniversalButtonRenderer
              }}
            />
          </TextContainer>
          <GridUnit size={{ md: 1 / 12 }} />
          <GridUnit size={{ sm: 1, md: 6 / 12, xl: 8 / 12 }}>
            <IndexBlock3.Image
              fluid={{ ...props.pageContext.data?.mediaImage3[0].fluid }}
            />
          </GridUnit>
        </IndexBlock3>
        <IndexBlock3 reverseMobile>
          <GridUnit size={{ sm: 1, md: 6 / 12, xl: 8 / 12 }}>
            <IndexBlock3.Image
              fluid={{ ...props.pageContext.data?.mediaImage3[1].fluid }}
            />
          </GridUnit>
          <TextContainer size={{ sm: 1, md: 6 / 12, xl: 4 / 12 }}>
            <IndexBlock3.Tag>
              {props.pageContext.data?.block3bTitleSmall}
            </IndexBlock3.Tag>
            <IndexBlock3.Title>
              {props.pageContext.data?.block3bHeadlineBig}
            </IndexBlock3.Title>
            <IndexBlock3.SubTitle>
              {props.pageContext.data?.block3bHeadlineSmall}
            </IndexBlock3.SubTitle>
            <IndexBlock3.Text>
              {props.pageContext.data?.block3bSub}
            </IndexBlock3.Text>
            <ReactMarkdown
              source={props.pageContext.data?.block3bCta.block3bCta}
              renderers={{
                paragraph: ParagraphRenderer,
                link: UniversalButtonRenderer
              }}
            />
          </TextContainer>
        </IndexBlock3>
      </BlocksContainer>
      <IndexTeam2>
        <Container>
          <Grid>
            <GridUnit size={{ sm: 1, md: 5 / 12 }}>
              <IndexTeam2.Content>
                <IndexTeam2.Title>
                  {props.pageContext.data?.block4Headline}
                </IndexTeam2.Title>
                <IndexTeam2.SubTitle>
                  {props.pageContext.data?.block4TitleSmall}
                </IndexTeam2.SubTitle>
                <IndexTeam2.Text>
                  {props.pageContext.data?.block4Sub}
                </IndexTeam2.Text>
                <ReactMarkdown
                  source={props.pageContext.data?.block4Cta.block4Cta}
                  renderers={{
                    paragraph: ParagraphRenderer,
                    link: UniversalButtonRenderer
                  }}
                />
              </IndexTeam2.Content>
            </GridUnit>
            <ImageWrapper
              size={{ sm: 1, md: 7 / 12 }}
              as={IndexTeam2.ImageContainer}
            >
              <IndexTeam2.Image
                fluid={{ ...props.pageContext.data?.mediaImage4.fluid }}
              />
            </ImageWrapper>
          </Grid>
        </Container>
      </IndexTeam2>
      <Container>
        <ClientArea>
          {props.pageContext.data?.logo.map((logo, index) => (
            <ClientArea.Client key={index}>
              <ClientArea.Image fluid={{ ...logo.fluid }} />
            </ClientArea.Client>
          ))}
        </ClientArea>
      </Container>
      <Gallery>
        {props.pageContext.data?.block5Table.map(media => (
          <GalleryItem key={media.id}>
            <GalleryItem.StyledLink href={media.link}>
              <GalleryItem.Image fluid={{ ...media.image.fluid }} />
              <GalleryItem.Text>{media.label}</GalleryItem.Text>
            </GalleryItem.StyledLink>
          </GalleryItem>
        ))}
      </Gallery>
    </Layout>
  </>
)

export default IndexPage
