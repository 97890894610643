import styled from 'styled-components'

const Text = styled.p`
  color: #6b6b6b;
  font-family: 'Merriweather', serif;
  font-style: italic;
  font-weight: 400;
  margin: 0;
  font-size: 15px;
  line-height: 21.6px;
  letter-spacing: 3.6px;
`

export default Text
