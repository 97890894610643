import styled from 'styled-components'

const Content = styled.div`
  min-height: 600px;
  padding-left: 40px;
  padding-top: 47px;
  position: relative;
  background: #ffffff;
  box-shadow: 5px 5px 0 12px #f2f1f1;
  margin-right: 17px;

  @media screen and (max-width: 768px) {
    min-height: 400px;
    box-shadow: 0 0 0 5px #f2f1f1;
  }

  @media screen and (max-width: 576px) {
    margin-right: 0;
    margin-bottom: 20px;
    padding-bottom: 30px;
  }
`

export default Content
