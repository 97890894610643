import styled, { css } from 'styled-components'
import { grid } from 'styled-components-grid'
import Image from './Image'
import SubTitle from './SubTitle'
import Tag from './Tag'
import Text from './Text'
import Title from './Title'

const reverseMobile = css`
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const IndexBlock3 = styled.section`
  ${grid({})};
  padding: 0 45px 90px 45px;

  @media screen and (max-width: 576px) {
    padding: 0 0 20px 0;
  }

  ${props => props.reverseMobile && reverseMobile}
`

IndexBlock3.Image = Image
IndexBlock3.SubTitle = SubTitle
IndexBlock3.Tag = Tag
IndexBlock3.Text = Text
IndexBlock3.Title = Title

export default IndexBlock3
