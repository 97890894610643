import GatsbyImage from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const Img = styled(GatsbyImage)`
  position: absolute !important;
  right: 45px;
  top: 7%;
  width: 60%;
  max-width: 100%;

  @media (max-width: 1199px) {
    position: relative !important;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

const Image = props => <Img {...props} className='lazyload' />

export default Image
