import styled from 'styled-components'

const BottomWrapper = styled.div`
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1199px) {
    width: 100%;
    bottom: 0;
  }
`

export default BottomWrapper
