import GatsbyImage from 'gatsby-image'
import styled from 'styled-components'

const Image = styled(GatsbyImage)`
  width: 100px;
  height: auto;
  img {
    object-fit: contain !important;
  }
`

export default Image
