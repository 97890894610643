import styled from 'styled-components'

const Header = styled.h1`
  position: absolute;
  left: 150px;
  top: 15%;
  width: 50%;
  font-size: 40px;
  letter-spacing: 24px;
  line-height: 1.5;
  background-color: #f2f1f178;
  z-index: 5;
  font-weight: bold;
  display: inline-grid;

  @media (max-width: 1199px) {
    font-size: 28px;
    letter-spacing: 12px;
    text-align: center;
    margin-top: 75px;
    left: 5%;
    top: 0;
    right: 30%;
    width: 65%;
  }

  @media (max-width: 767px) {
    margin-top: 50px;
    left: 5%;
    top: 0;
    right: 0;
    width: 90%;
    font-size: 28px;
    line-height: 1;
    letter-spacing: 12px;
  }
  @media (max-width: 481px) {
    margin-top: 30px;
    left: 5%;
    top: 0;
    right: 0;
    width: 90%;
    font-size: 20px;
  }
`

export default Header
