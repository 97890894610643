import styled from 'styled-components'

const Text = styled.p`
  color: #6b6b6b;
  font-family: 'Merriweather', serif;
  font-size: 15px;
  font-style: italic;
  letter-spacing: 3.6px;
  margin-bottom: 25px;
  font-weight: 400;
  line-height: 1.661;

  @media (max-width: 1599px) and (min-width: 1400px) {
    margin-bottom: 0;
  }
`

export default Text
