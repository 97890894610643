import styled from 'styled-components'
import ButtonText from './ButtonText'

const Button = styled.a`
  margin-top: 12px;
  background: #000000 none repeat scroll 0 0;
  height: 30px;
  text-align: center;
  width: 190px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease 700ms;
  text-decoration: none;

  &:hover {
    background-color: #ffffff;
    ${ButtonText} {
      color: #000000;
      &:before,
      &:after {
        color: #000000;
      }
    }
  }
`

export default Button
