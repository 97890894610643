import GatsbyImage from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const Img = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const Image = props => <Img {...props} className='lazyload' />

export default Image
