import styled from 'styled-components'

const FakeDiv = styled.div`
  background: #f2f1f1 none repeat scroll 0 0;
  height: 910px;
  width: 785px;

  @media (max-width: 1399px) and (min-width: 1200px) {
    height: 656px;
    width: 685px;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

export default FakeDiv
