import styled from 'styled-components'
import ButtonText from './ButtonText'

const Button = styled.div`
  position: relative;
  background: #000000 none repeat scroll 0 0;
  height: 30px;
  text-align: center;
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease 700ms;
  margin-bottom: 15px;

  &:hover {
    background-color: #ffffff;
    ${ButtonText} {
      color: #000000;
      &:before,
      &:after {
        color: #000000;
      }
    }
  }

  @media (max-width: 992px) {
    position: relative;
    left: 0;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 75%;
  }

  @media (max-width: 576px) {
    left: 0;
    bottom: 0;
  }
`

Button.defaultProps = {
  left: true
}

export default Button
