import styled from 'styled-components'
import Box from './Box'
import Button from './Button'
import ButtonText from './ButtonText'
import Image from './Image'
import Text from './Text'
import Title from './Title'

const IndexBlockD = styled.div`
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: 992px) {
    padding: 0 0 100px 0;
  }

  @media screen and (max-width: 576px) {
    padding-bottom: 0;
  }
`

IndexBlockD.Box = Box
IndexBlockD.Image = Image
IndexBlockD.Text = Text
IndexBlockD.Title = Title
IndexBlockD.Button = Button
IndexBlockD.ButtonText = ButtonText

export default IndexBlockD
