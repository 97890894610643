import styled from 'styled-components'

const ImageContainer = styled.div`
  display: inline-flex;
  margin-top: -7px;
  margin-bottom: -17px;
  z-index: 2;
`

export default ImageContainer
