import styled from 'styled-components'
import Box from './Box'
import Image from './Image'
import Text from './Text'
import Title from './Title'

const IndexBlockC = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: end;
  height: 100%;
  justify-content: space-between;

  @media (max-width: 992px) {
    padding: 0 0 100px 0;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
  @media screen and (max-width: 576px) {
    padding: 0 0 20px 0;
  }
`

IndexBlockC.Box = Box
IndexBlockC.Image = Image
IndexBlockC.Text = Text
IndexBlockC.Title = Title

export default IndexBlockC
