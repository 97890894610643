import styled from 'styled-components'

const Title = styled.h5`
  font-size: 28px;
  line-height: 1;
  padding-bottom: 17px;
  letter-spacing: 10.8px;
  font-weight: 700;
  color: #000000;

  @media (max-width: 481px) {
    font-size: 20px;
  }
`

export default Title
