import styled from 'styled-components'

const Title = styled.h5`
  line-height: 1.2;
  padding-bottom: 10px;
  font-size: 25px;
  letter-spacing: 6.8px;
  color: #000000;
  font-weight: 700;
  overflow-wrap: break-word;
`

export default Title
