import styled from 'styled-components'

const Title = styled.h5`
  font-size: 16px;
  text-transform: uppercase;
  color: #000;
  margin: 0 0 25px;
  line-height: 1.5;
  letter-spacing: 5px;
  font-weight: 700;
`

export default Title
