import styled from 'styled-components'

const SubTitle = styled.h3`
  margin: 0;
  padding: 0 0 10px 0;
  font-weight: 700;
  color: #000000;
  font-size: 0.9rem;
  text-align: end;
  letter-spacing: 2px;
`

export default SubTitle
