import styled from 'styled-components'

const Text = styled.p`
  font-size: 13px;
  letter-spacing: 3.6px;
  color: #6b6b6b;
  margin-bottom: 19px;
  line-height: 1.661;
  font-weight: 400;
`

export default Text
