import styled from 'styled-components'
import Content from './Content'
import Image from './Image'
import ImageContainer from './ImageContainer'
import StyledLink from './StyledLink'
import SubTitle from './SubTitle'
import Text from './Text'
import Title from './Title'

const IndexTeam2 = styled.section`
  padding: 0 60px;
  margin: 5px 0 20px 0;

  @media screen and (max-width: 576px) {
    padding: 0 15px;
  }
`

IndexTeam2.Content = Content
IndexTeam2.Image = Image
IndexTeam2.ImageContainer = ImageContainer
IndexTeam2.StyledLink = StyledLink
IndexTeam2.SubTitle = SubTitle
IndexTeam2.Text = Text
IndexTeam2.Title = Title

export default IndexTeam2
