import styled from 'styled-components'
import Client from './Client'
import Image from './Image'

const ClientArea = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 100px 0;
`

ClientArea.Client = Client
ClientArea.Image = Image

export default ClientArea
