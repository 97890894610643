import styled from 'styled-components'

const Text = styled.p`
  color: #000;
  font-family: 'Merriweather', serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.8);
  transition: all 700ms ease;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 100%;
  text-align: center;
  padding: 20px;
`

export default Text
