import styled from 'styled-components'
import BottomWrapper from './BottomWrapper'
import Button from './Button'
import ButtonText from './ButtonText'
import FakeDiv from './FakeDiv'
import Header from './Header'
import Image from './Image'
import Text from './Text'

const IndexHeader = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 45px;
  margin-bottom: 45px;

  @media (max-width: 1199px) {
    padding: 0 15px;
  }
`

IndexHeader.Button = Button
IndexHeader.ButtonText = ButtonText
IndexHeader.Header = Header
IndexHeader.Image = Image
IndexHeader.BottomWrapper = BottomWrapper
IndexHeader.Text = Text
IndexHeader.FakeDiv = FakeDiv

export default IndexHeader
