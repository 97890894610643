import styled from 'styled-components'

const Box = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 400px;
  height: 95%;
  border: 5px solid #fff;
  padding: 26px 30px 0 25px;
  background-color: #f4f4f4c7;
  z-index: 1;
  overflow-wrap: break-word;

  @media screen and (max-width: 1599px) and (min-width: 1400px) {
    width: 500px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export default Box
