import styled from 'styled-components'
import Box from './Box'
import Image from './Image'
import Text from './Text'
import Title from './Title'

const IndexBlockB = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: end;
  height: 100%;
  justify-content: space-between;

  @media (max-width: 992px) {
    padding: 0 0 100px 0;
  }

  @media screen and (max-width: 576px) {
    padding: 0 0 20px 0;
  }
`

IndexBlockB.Box = Box
IndexBlockB.Image = Image
IndexBlockB.Text = Text
IndexBlockB.Title = Title

export default IndexBlockB
