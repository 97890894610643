import styled from 'styled-components'
import { grid } from 'styled-components-grid'
import Image from './Image'
import StyledLink from './StyledLink'
import Text from './Text'

const GalleryItem = styled.div`
  ${grid.unit({ size: { sm: 1, md: 1 / 2, lg: 1 / 3, xl: 1 / 5 } })};
  padding: 1px 6px 0 6px;
  position: relative;

  &:hover ${Text} {
    opacity: 1;
  }
`

GalleryItem.Image = Image
GalleryItem.StyledLink = StyledLink
GalleryItem.Text = Text

export default GalleryItem
